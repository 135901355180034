<template>
  <div>
    <div v-if="!isFetching">
      <h1 class="h1">HEARDLEVISION</h1>
      <h2 class="h2">Eurovision Music Quiz</h2>
    </div>
    <div class="btn btn-primary" v-on:click="startQuiz">
      <img :src="mySVG" />
    </div>
    <div class="input-wrapper">
      <div class="progressbar">
        <div
          class="progressbar-fill"
          :style="{ width: this.progressSteps[this.try].progress + '%' }"
        ></div>
      </div>
    </div>
    <div class="input-wrapper">
      <div class="shortlist-list">
        <div
          class="shortlist-item"
          v-for="item in shortlist"
          :key="item.id"
          v-on:click="pickGuess"
        >
          {{ item }}
        </div>
      </div>

      <svg
        class="search-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>
      <input
        v-model="guess"
        v-on:input="inputguess"
        class="input-div"
        id="autoComplete"
        type="search"
        dir="ltr"
        spellcheck="false"
        autocorrect="off"
        autocomplete="off"
        autocapitalize="off"
        aria-controls="autoComplete_list_1"
        aria-autocomplete="both"
        placeholder="Know it? Search for the artist / title"
        role="combobox"
        aria-owns="autoComplete_list_1"
        aria-haspopup="true"
        aria-expanded="false"
      />
      <svg
        class="reset-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </div>
    <div class="cta-button-wrapper">
      <button class="cta-button" v-on:click="skipGuess">
        {{ progressSteps[this.try].nextSeconds }}
      </button>
      <button class="cta-button" v-on:click="checkGuess">Submit</button>
    </div>

    <div class="guesses-list">
      <div
        class="guesseslist-item"
        v-for="item in guesses"
        :key="item.id"
        v-on:click="pickGuess"
      >
        {{ item.id }}. {{ item.status }} {{ item.guess }}
      </div>
    </div>
    <div class="solution-wrapper" v-if="this.solution">
      <div class="solution-div">
        <h2>The correct answer is:</h2>
        <h3>{{ this.quiz.artist }} - {{ this.quiz.song }}</h3>
        <h4>
          {{ this.quiz.country }} {{ this.quiz.year }} ({{ this.quiz.place }})
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      quiz: {},
      isFetching: true,
      mySVG: require("../assets/play-button-svgrepo-com.svg"),
      list: [],
      shortlist: [],
      guess: "",
      count: 0,
      guesses: [],
      try: 0,
      solution: false,
      progressSteps: [
        {
          nextSeconds: "Skip (+1s)",
          timeout: "1000",
          progress: "6.25",
        },
        {
          nextSeconds: "Skip (+2s)",
          timeout: "2000",
          progress: "12.5",
        },
        {
          nextSeconds: "Skip (+3s)",
          timeout: "4000",
          progress: "25",
        },
        {
          nextSeconds: "Skip (+4s)",
          timeout: "7000",
          progress: "43.75",
        },
        {
          nextSeconds: "Skip (+5s)",
          timeout: "11000",
          progress: "68.75",
        },
        {
          nextSeconds: "Give Up",
          timeout: "16000",
          progress: "100",
        },
      ],
    };
  },
  created() {
    this.checklogin();
  },
  methods: {
    checklogin() {
      const specific_date = Date.now();
      const current_date = localStorage.getItem("sha1");
      if (current_date > specific_date) {
        this.isFetching = false;
        this.getQuiz();
        this.getList();
      } else {
        window.location.href = "/login";
      }
    },
    async getQuiz() {
      const accessdata = {
        withCredentials: true,
      };

      try {
        const res = await axios.post(
          "https://heardlevision.spacekit.de/api/?getQuiz",
          // "http://localhost/eurovisle/api/?getQuiz",
          accessdata,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (res.status === 200) {
          this.quiz = res.data;
        } else {
          this.msg = "something went wrong";
        }
      } catch (e) {
        this.msg = "something went wrong";
        console.error(e);
      }
    },
    async getList() {
      const accessdata = {
        withCredentials: true,
      };

      try {
        const res = await axios.post(
          "https://heardlevision.spacekit.de/api/?list",
          // "http://localhost/eurovisle/api/?list",
          accessdata,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (res.status === 200) {
          this.list = res.data;
        } else {
          this.msg = "something went wrong";
        }
      } catch (e) {
        this.msg = "something went wrong";
        console.error(e);
      }
    },
    startQuiz() {
      const myTrack = new Audio(this.quiz.preview_url);
      myTrack.play();
      setTimeout(() => {
        myTrack.pause();
        myTrack.currentTime = 0;
      }, this.progressSteps[this.try].timeout);
    },
    inputguess() {
      if (this.guess.length > 0) {
        this.count = 0;
        this.shortlist = this.list.filter((item) => {
          return (
            item.toLowerCase().includes(this.guess.toLowerCase()) &&
            this.count++ < 5
          );
        });
      } else {
        this.shortlist = [];
      }
    },
    pickGuess(e) {
      this.guess = e.target.innerText;
      this.shortlist = [];
    },
    checkGuess() {
      if (this.guess.length > 0) {
        if (
          this.guess.toLowerCase() ===
          this.quiz.artist.toLowerCase() + " - " + this.quiz.song.toLowerCase()
        ) {
          const item = {
            id: this.guesses.length + 1,
            status: "✅",
            guess: this.guess,
          };
          this.guesses.push(item);
          this.guess = "";
        } else {
          if (this.try < 5) {
            this.try = this.try + 1;
            const item = {
              id: this.guesses.length + 1,
              status: "❌",
              guess: this.guess,
            };
            this.guesses.push(item);
          } else {
            this.solution = true;
          }
          this.guess = "";
        }
      } else {
        alert("Please enter an answer!");
      }
    },
    skipGuess() {
      if (this.try < 5) {
        this.try = this.try + 1;
        const item = {
          id: this.guesses.length + 1,
          status: "❌",
          guess: " - skipped -",
        };
        this.guesses.push(item);
      } else {
        this.solution = true;
      }
    },
  },
};
</script>

<style scoped>
.h1 {
  margin-bottom: 0.1rem;
}
.h2 {
  margin-top: 0.1rem;
  margin-bottom: 20px;
}
.btn {
  width: 50px;
  margin: 0 auto 20px auto;
}
.btn img {
  fill: green;
}
.cta-button {
  border: 1px solid dimgrey;
  padding: 5px 10px;
  cursor: pointer;
}
.cta-button-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  margin: 10px auto 0 auto;
}
.reset-icon {
  position: absolute;
  top: 10px;
  right: 7px;
}
.search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.input-div {
  width: 350px;
  padding: 10px 10px 10px 35px;
  border: 1px #b2b2b2 solid;
}
.input-wrapper {
  position: relative;
  max-width: 350px;
  margin: auto;
}
.shortlist-list {
  position: absolute;
  bottom: 37px;
}
.shortlist-item {
  padding: 5px 10px;
  border: 1px solid #b2b2b2;
  cursor: pointer;
  list-style-type: none;
  width: 328px;
}
.guesses-list {
  text-align: initial;
  max-width: 350px;
  margin: 15px auto 0 auto;
}
.guesseslist-item {
  padding: 5px 10px;
  border: 1px solid #b2b2b2;
  cursor: pointer;
  list-style-type: none;
  width: 328px;
  margin-bottom: 10px;
}
.progressbar {
  width: 100%;
  height: 10px;
  background-color: #3a3a3a;
  border-radius: 1px;
  margin: 10px 0;
}
.progressbar-fill {
  height: 10px;
  background-color: #b2b2b2;
  border-radius: 1px;
  transition: width 0.2s ease-in-out;
}
</style>
